import {defineStore} from 'pinia'
import {useForm} from "@inertiajs/vue3";


export const useAnswerStore = defineStore('answer', {
    state: () => ({
        answerContent: '',
        answerStream: '',
        answerUnaware: false,

        isLoadingAnswer: false,
        isLoadingVoice: false,

        isAnswerInterrupted: false,
    }),


    persist: {
        storage: localStorage,
        paths: ['answerContent', 'answerStream']
    },
})

